.audience-rule-resolver {
    .p-dropdown.p-component.p-inputwrapper {
        height: 42px;
        border-radius: 21px;

        .p-dropdown-label.p-inputtext {
            display: flex;
            align-items: center;
        }
    }

    .p-inputtext.p-component {
        height: 42px;
        border-radius: 21px;
    }

    .p-multiselect.p-component {
        height: 42px;
        border-radius: 21px !important;

        .p-multiselect-label-container {
            display: flex;
            align-items: center;
        }

        .p-multiselect-token {
            background: #E3F2FD;
            color: #1D5BA4;
        }
    }
}

.input-icon-left {
    position: relative;

    .icon-left {
        position: absolute;
        z-index: 1;
        left: 17px;
        top: 50%;
        transform: translateY(-50%);
    }

    .p-dropdown.p-component.p-inputwrapper {
        padding-left: 34px;
    }

    .p-calendar.p-component.p-inputwrapper {
        .p-inputtext.p-component {
            padding-left: 42px !important;
        }
    }
}