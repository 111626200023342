.heatmap {

    &-message-connections-only {
        align-items: center !important;
        display: flex !important;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            background: #aa5608;
            height: 100%;
            width: 7px;
            z-index: 1;
            left: -7px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        .p-inline-message-icon.pi.pi-info-circle {
            height: 19px;
            width: 19px;
            &::before {
                font-size: 20px;
            }

        }
        .p-inline-message-text {
            margin-left: 3px;
            font-size: 14px !important;
        }
    }

    &-container {
        position: relative;
    }

    &-map {
        display: block;
        width: 100%;
        filter: grayscale(1)
    }

    &-heat-points {

        background-size: cover;
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(2, 1fr);
        border-radius: 1rem;
        padding: 1rem;
        position: absolute;
        top: 0;
        overflow: visible;
        background-blend-mode: multiply;
        background-repeat: no-repeat;

        @media (max-width: 800px) {

            .ap {
                scale: .5;
                --ap-translate: -100%;
            }

        }

        @media (max-width: 500px) {

            .ap {
                scale: .4;
                --ap-translate: -130%;
            }

        }

    }

    &-store {
        margin-right: 2rem;
        display: flex;
        align-items: center;

        &-name {
            color: #194893;
            font-weight: 600;
        }
    }

    &-bar {
        height: 8rem;
        width: 1rem;
        border-radius: 4px;
        background: linear-gradient(0deg,
                #0999D7,
                hsla(66, 47%, 58%, 1) 25%,
                hsla(48, 90%, 69%, 1) 50%,
                hsla(30, 89%, 67%, 1) 75%,
                hsla(357, 89%, 64%, 1) 100%);


        &-container {
            display: flex;
            align-items: stretch;
            position: absolute;
            bottom: 1rem;
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 3px;
            padding: 0.2rem;
            gap: 0.5rem;
        }

        &__symbols {
            display: flex;
            max-width: 68px;
            justify-content: space-between;
            flex-direction: column-reverse;

            p {
                margin: 0;
                text-align: center;
            }
        }
    }
}

.ap-container {
    position: absolute;
    height: 100%;
    width: 100%;

}

.ap {
    --ap-translate: -50%;
    transform: translate(var(--ap-translate), var(--ap-translate));
    height: 20px;
    width: 20px;
    border-radius: 50px;
    border-width: 2px;
    border-style: solid;
    position: relative;
    z-index: 1;

    &:hover+.ap-info {
        display: block;
    }



    &-info {
        margin-left: 1.2rem;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 0 9px 2px #5f5f5f70;
        position: absolute;
        z-index: 10000;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        padding: 0.5rem;
        border-radius: 0.5rem;
        min-width: 8rem;
        display: none;
    }


    &-department,
    &-location,
    &-users {
        margin: 0;
    }

    &-location {
        font-weight: 700;
    }
}

.map-message {
    .p-inline-message-icon.pi.pi-info-circle {
        color: #007EB4;
        width: 20px;
        height: 20px;
        margin-right: 12px;

        &::before {
            display: inline-block;
            width: 20px;
            height: 20px;
            transform: scale(1.2) translateX(2px) translateY(2px);
        }
    }
}


.p-calendar.p-component.p-inputwrapper.p-inputwrapper-filled::before {
    font-family: 'primeicons';
    content: "\e902";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 18px;
    bottom: 43%;
    color: #6c757d;
    pointer-events: none;
}


.top-map-area {
    position: relative;
    padding-top: 12px;

    &::after {
        display: block;
        content: '';
        width: 10px;
        height: 2px;
        background-color: #dee2e6;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 100;
    }
}

.p-ai-center .p-tabview .p-tabview-panels {
    padding: 0;
}

.ap-ranking-table {

    .p-datatable-scrollable-header-box {
        margin-right: 0 !important;
        background-color: #E0E0E0;

    }

    .p-datatable-scrollable-body {
        overflow: auto;
    }
    
    
    &-desc {
        tr {

            .p-sortable-column-icon.pi.pi-fw  {
                
                &::before {
                    transform: rotate(180deg);
                }
            }
        }
    }

    tr[role="row"] {
        // display: flex;
        th:first-child{ 
            padding-left: 1.5em;
            width: 50% !important;
        }

        th {
            font-family: Nunito !important;
            font-weight: 800 !important;
            background: red;
            text-align: left !important;
            padding-right: 32px !important;
            font-family: Nunito;
            font-size: 14px;
            font-weight: 800;
            line-height: 17px;
            text-align: left;
            width: 50% !important;
            padding-top: 6px !important;
            padding-bottom: 6px !important;

            &:hover, &:focus {
                border-radius: 0px !important;
            }
        }

        td {
            padding-top: 6px !important;
            padding-bottom: 6px !important;
        }

        td:nth-child(2) {
            text-align: left;
        }

        .p-sortable-column-icon.pi.pi-fw {

            &::before {
                display: block;
                content: '';
                width: 10px;
                height: 5px;
                background-color: black;
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                position: absolute;
                right: 12px;
                top: 50%;
            }

            &.pi-sort-amount-down::before {
                transform: rotate(180deg);
            }
        }
    }
}
.contact-filter-container {
    position: relative;

    &__label {
        font-family: Nunito;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.37px;
        text-align: left;
        position: absolute;
        margin: 0;
        top: -14px;
    }

    &__btn-group {
        max-height: 39px;
        position: relative;
        top: 4px;
    }
}

.stats-section {
    width: 266px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-top: 8px;

    &__title {
        font-family:Nunito;
        font-size:20px;
        font-weight:700;
        line-height:27px;
        letter-spacing:0em;
        text-align:left;
        color:#194893;
        }

    .stat {
        display: flex;
        flex-direction: column;
        gap: 4px;
        text-align: left;

        &__title {
            font-size: 14px;
            margin: 0;
        }

        &__value {
            font-weight: 700;
            margin: 0px;
        }
    }
}