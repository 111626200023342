.statistics-main-container {
  display: grid;
  /*grid-template-columns: 2fr 10fr;*/
}

.statistics-menu-container {
  display: flex;
  width: 100%;
  gap: 2rem;
  margin-top: -8px;
  margin-left: -8px;
  grid-template-columns: repeat(3, 1fr);

  div:first-child {
    flex: 1;
  }

  .vertical-divider {
    width: 10px;
    background: rgb(180, 180, 180);
  }

  .header-titulo-pagina {
    height: 62px;
  }
}

.title-image {
  width: 30px; /* Ajusta el tamaño de la imagen según tus necesidades */
  height: auto;
  margin-right: 10px; /* Espacio entre la imagen y el texto */
}
.statistics-title-download {
  display: flex;
  justify-content: space-between; /* Alinea el texto y el botón a los extremos */
  align-items: center; /* Centra verticalmente el contenido */
  font-size: 24px; /* Tamaño de fuente */
  font-weight: bold; /* Negrita */
  color: #194893; /* Color del texto */
  margin-top: 10px; /* Espaciado superior */
  //background-color: white; /* Fondo blanco */
  padding: 10px; /* Espaciado interior */

  div:first-child {
    flex: 1;
  }

  .vertical-divider {
    width: 10px;
    background: rgb(180, 180, 180);
  }

  .header-titulo-pagina {
    height: 62px;
  }
}

.statistics-title-download:empty {
  height: 0px;
  margin: 0;
  padding: 0;
}

.statistics-menu-filters {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan en la siguiente línea cuando no haya suficiente espacio */
  gap: 0.1rem; /* Espacio entre los elementos */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
  margin: 0; /* Elimina márgenes negativos */
}

.statistics-menu-filters .p-col-12 {
  flex: 1 1 calc(25% - 2rem); /* Ajusta según el ancho deseado */
  min-width: 150px; /* Opcional: establece un ancho mínimo */
  flex-grow: 0 !important;
}

.statistic-page
  .p-multiselect-panel
  .p-multiselect-header
  .p-checkbox.p-component {
  position: relative;
}

.statistic-page
  .p-multiselect-panel
  .p-multiselect-items.p-component:empty::after {
  content: "No hay registros para mostrar";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #495057;
  font-weight: normal;
}

.statistic-page
  .p-multiselect-panel
  .p-multiselect-header
  .p-checkbox.p-component::after {
  position: absolute;
  content: "Todo";
  text-wrap: nowrap;
  color: #495057;
  font-weight: normal;
  margin-left: 1.85rem;
}

.p .vertical-divider {
  width: 10px;
  background: rgb(180, 180, 180);
}

.header-titulo-pagina {
  height: 62px;
}

.custom-neocal-class {
  flex: 1 1 calc(25% - 2rem); /* Ajusta según el ancho deseado */
  min-width: 150px; /* Opcional: establece un ancho mínimo */
}

.loader {
  display: flex;
  flex-direction: column; /* Alinea el spinner y el texto en columna */
  //justify-content: center; /* Centra verticalmente el contenido */
  align-items: center; /* Centra horizontalmente el contenido */
  //height: 100vh; /* Ajusta la altura del contenedor a la vista del navegador */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
  text-align: center; /* Centra el texto horizontalmente */
}

.custom-spinner .p-progress-spinner-circle {
  stroke: black !important; /* Cambia el color del spinner a negro */
}

@media (max-width: 768px) {
  .statistics-menu-filters {
    flex-direction: column; /* Cambia a columna en pantallas pequeñas */
    gap: 0.1rem; /* Ajusta el espacio entre los elementos en la vista de columna */
  }

  .statistics-menu-filters > * {
    flex: 1 1 100%; /* Asegura que cada elemento ocupe el 100% del ancho disponible en pantallas pequeñas */
    min-width: auto; /* Elimina el ancho mínimo en pantallas pequeñas */
  }
}

.p-menu.menu-statistics {
  border: none;
  background-color: unset;
  width: fit-content;
  margin-left: 4rem;

  .p-menuitem {
    display: inline;
    .p-menuitem-link {
      color: #009d63;
      border-radius: 0.4rem;

      span {
        color: inherit;
        font-size: 0.825rem;
        font-weight: 600;
        line-height: 26px;
        transition: all 0.3s;
        display: block;
      }

      &:not(.p-disabled):hover {
        background: #006a43;

        span {
          color: #fff;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      .p-menuitem-link {
        background: #009d63;

        span {
          color: #fff;
        }
      }
    }
  }
}

.looker-container {
  height: calc(100vh - 8.5rem);
  height: calc(100dvh - 8.5rem);
  overflow-y: auto;

  iframe {
    margin-top: -1rem;
    margin-left: 0.2rem;
    width: calc(100% + 0.5rem);
    border: none;
    height: calc(100% + 20px);
  }
}

.p-menu-list {
  display: flex;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: green !important;
  color: green !important;
}

/* Asegúrate de que los estilos se aplican correctamente al contenedor del MenuBar */
.tab-style-menu .p-menubar {
  display: flex;
  border-bottom: 1px solid #ddd; /* Línea de separación en la parte inferior */
}

.tab-style-menu .p-menuitem {
  position: relative;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #333; /* Color de texto por defecto */
  transition: color 0.3s;
}

.tab-style-menu .p-menuitem:hover {
  color: #007ad9; /* Color del texto en hover */
}

.tab-style-menu .p-menubar .p-menuitem.active {
  color: #007ad9; /* Color del texto activo */
  border-bottom: 3px solid #007ad9; /* Línea de color debajo del botón activo */
  background-color: transparent; /* Asegura que el fondo sea transparente */
}

.button-with-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-container {
  display: flex;
  align-items: center;
}
