.p-menubar.navbar-principal {
    border: none;
    
    &.blue-nav {
        background: linear-gradient(165deg, $main-color-1, darken($main-color-1, 10));
    }
}

.arrow {
    &.segunda {
        background-image: url('../../img/common/back-image-cta.svg');
    }
}

.stars--yellow > span {
    color: #FFB963!important;
}

.p-datatable {
    table {
        tbody.p-datatable-tbody {
            tr.disabled {
                background-color: var(--surface-200);
                cursor: not-allowed;
            }
        }
    }
}

.p-datatable {
    .p-datatable-scrollable-header-box {
        margin-right: 0px !important;
    }
    
    .p-datatable-emptymessage td {
        text-align: center !important;
    }
}

.navbar-principal {
    .p-submenu-icon.pi.pi-angle-down {
        border-bottom: none !important;
    }

    .p-menuitem {
        .p-submenu-list {
            margin-top: -32px;
            margin-left: 15px;
            max-width: calc(100% - 15px);
        }

        &.p-menuitem-active {
            .p-submenu-list {
                display: none !important;
            }
        }

        &:hover {
            .p-submenu-list {
                display: block !important;
            }
        }

        .p-menuitem.p-menuitem-active.p-menuitem-active {
            color: #707087;
            font-weight: 700;
        }
    }
}