.view-only-mode {
  .ad-content-manager {
    @media screen and (max-width: 1186px) {
      flex-direction: column !important;
    }
  }

  .mobile-screen {
    padding-top: 26px !important;
    width: auto;
    height: 365px;
  }

  .desktop-screen {
    padding: 29.5px 53px 28px 53px !important;

    width: auto;
    height: 365px;
  }

  .banner-card-desktop {
    padding: 12px;
  }

  .banner-card-mobile {
  }

  .desktop {
    .p-fileupload-content {
      width: 374.3px;
      height: 206.51px !important;
    }
  }

  .mobile {
    .p-fileupload-content {
      width: 171px;
      height: 214px !important;
    }
  }

  .p-button.p-fileupload-choose {
    display: none !important;
  }

  .vertical-divider,
  .divider {
    display: none !important;
  }

  .android-ios-switch-button {
    padding: 11px 20px !important;
  }
}

.ad-general-info-details-card {
  margin: 0px !important;
  width: 357px;
  height: 222px;
}

.ad-results-details-card {
  margin: 0px !important;
  width: 357px;
  height: 384px;
}

.ad-os-impression-distribution-list {
  max-height: 110px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;

  .os-item {
    font-size: 14px;
    color: #000;
  }

  .color-\#3B82F6::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #3b82f6;
  }
  .color-\#22C55E::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #22c55e;
  }
  .color-\#EAB308::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #eab308;
  }
  .color-\#06B6D4::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #06b6d4;
  }
  .color-\#EC4899::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #ec4899;
  }
  .color-\#6366F1::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #6366f1;
  }
  .color-\#14B8A6::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #14b8a6;
  }
  .color-\#F97316::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #f97316;
  }
  .color-\#64748B::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #64748b;
  }
  .color-\#A855F7::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #a855f7;
  }
  .color-\#FF3D32::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    background: #ff3d32;
  }

  .color-\#ea4335::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;

    background: #ea4335;
  }

  .color-\#2196f3::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;

    background: #2196f3;
  }

  .color-\#246209::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;

    background: #246209;
  }

  .color-\#194893::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;

    background: #194893;
  }

  .color-\#f0f0f0::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;

    background: #f0f0f0;
  }
}

.ad-os-impression-distribution-container {
  margin-top: -22px;

  &.empty {
    margin-top: 0px;
    display: flex;
    height: 125px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;

    span {
      font-size: 14px;
      text-align: center;
    }
  }
}

.ad-os-impression-distribution-chart {
  height: 152px !important;
  width: 152px !important;
}

.ad-stores-table {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #e8ecf2, 0px 4px 9px 1px rgba(214, 220, 233, 0.47);

  .store-row-group {
    font-size: 18px;
    font-weight: 700;
    color: #194893;
    margin-top: 13px;
    margin-bottom: 5px;
  }

  .p-datatable-emptymessage td {
    text-align: center !important;
  }

  .p-datatable-tbody .p-rowgroup-header:first-child {
    border-top: 1px solid #e9ecef;
  }

  .p-rowgroup-header td {
    border: none !important;
  }

  .p-datatable-thead > tr:nth-child(2),
  .p-datatable-tbody {
    box-shadow: none !important;
    border-radius: 0px !important;
  }

  .p-datatable-thead > tr:nth-child(2) > * + * {
    padding-left: 24px !important;
  }

  .p-datatable-thead > tr:nth-child(1) > * + * {
    padding-left: 24px !important;
  }

  .p-datatable-thead > tr:nth-child(2) > * {
    padding-top: 34px !important;
    padding-bottom: 16px !important;
  }

  .p-datatable-thead > tr:nth-child(1) > * {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 25px;
  }

  // selects the first td element inside a tr element
  .p-datatable-tbody tr td:first-child {
    padding-left: 0px !important;
  }

  // selects the last td element inside a tr element
  .p-datatable-tbody tr td:last-child {
    padding-right: 0px !important;
  }

  .p-datatable-thead {
    .p-filter-column {
      padding: 0px !important;

      .p-field.p-col {
        padding: 0px !important;
        margin: 0px !important;
      }

      .p-d-flex.p-col-12 {
        padding: 0px !important;
        margin: 0px !important;
        gap: 10px;

        .p-col-6 {
          flex: auto;
        }
      }
    }
  }
}

.text-left {
  text-align: left !important;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox.p-component {
  position: relative;
}

.p-multiselect-panel .p-multiselect-items.p-component:empty::after {
  content: "No hay registros para mostrar";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #495057;
  font-weight: normal;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox.p-component::after {
  position: absolute;
  content: "Todo";
  text-wrap: nowrap;
  color: #495057;
  font-weight: normal;
  margin-left: 1.85rem;
}
