.alert {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  font-weight: 500px;
  line-height: 19px;
}

.alert-primary {
  background: #dce8f9;
  color: #194893;
}
