.ad-content-manager {
  display: flex;
  justify-content: space-between;
  align-self: stretch;

  .vertical-divider {
    width: 1px;
    height: 532px;
    background: #e3e3e3;
  }
}

.screen-viewer {
  margin: 16px 0;
  border: 2.23px solid black;
  border-radius: 8px;
  padding: 27px 13px;
  overflow: auto;
  background: #ebebeb;
}

.mobile-screen {
  height: 437px;
  width: 237px;
  padding: 45px 13px 0px 13px;
}

.desktop-screen {
  height: 435px;
  width: 703px;
  padding: 21.5px 78px;

  .banner-logo {
    height: 19px;
  }
}

.banner-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &.hide {
    display: none;
  }
}

.banner-container-mobile {
  gap: 16px;
}

.banner-container-desktop {
  gap: 16px;
}

.p-buttonset {
  .android-ios-switch-button {
    padding: 11px 20px !important;
    width: 119px;
    height: 38px;
  }
}

.banner-input-file {
  .p-fileupload-content {
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px !important;
    border-style: dashed !important;
    border-radius: 3.38px !important;
    border-color: #194893 !important;
    background: #e7ebf1 !important;

    p {
      margin-top: 56px;
      font-size: 14.5px;
      color: #194893;
    }
  }

  &.file-uploaded {
    position: relative;

    &.mask-on-hover::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #0000008c;
      border-radius: 3.38px;
      opacity: 0;
      z-index: 1;

      transition: opacity 0.2s;
    }

    .p-fileupload-buttonbar {
      .p-button.p-fileupload-choose {
        opacity: 0 !important;
        transition: opacity 0.2s;
      }
    }
  }

  &.file-uploaded:hover {
    .p-fileupload-buttonbar {
      .p-button.p-fileupload-choose {
        opacity: 1 !important;
      }
    }

    &.mask-on-hover::before {
      opacity: 1;
    }
  }

  &.desktop {
    .p-fileupload-content {
      width: 529px;
      height: 296px !important;
    }

    .p-fileupload-buttonbar {
      .p-button.p-fileupload-choose {
        top: 103.5px;
      }
    }

    &.file-uploaded {
      .p-fileupload-buttonbar {
        .p-button.p-fileupload-choose {
          top: 114.5px;
        }
      }
    }

    .banner-image-preview-container {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: inherit;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
      }
    }
  }
  &.mobile {
    .p-fileupload-content {
      width: 196px;
      height: 245px !important;
    }

    .p-fileupload-buttonbar {
      .p-button.p-fileupload-choose {
        top: 78px;
      }
    }

    &.file-uploaded {
      .p-fileupload-buttonbar {
        .p-button.p-fileupload-choose {
          top: 103.5px;
        }
      }
    }

    .banner-image-preview-container {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: inherit;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
      }
    }
  }

  .banner-image-preview-container {
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  /* .banner-image-preview-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    opacity: 0;
    border-radius: inherit;
  } */

  .p-fileupload-buttonbar {
    .p-button.p-fileupload-choose {
      width: 176px;
      height: 38px;
      padding: 11px 20px 11px 20px;
      gap: 5px;
      border-radius: 19px;

      display: flex;
      gap: 4.25px;
      border-radius: 22.66px;
      background: #194893;

      .p-button-label,
      .p-button-icon {
        margin: 0px;
        text-wrap: nowrap;
        color: #ffffff !important;
        font-size: 14.5px;
        font-weight: 400;
        line-height: 19.78px;
      }
    }
  }
}

.banner-card {
  background: #ffffffbf;

  span {
    color: #000000;
  }
}

.banner-card-mobile {
  padding: 10.6px 7px;
  gap: 7px;
  border-radius: 3.5px;
  box-shadow: 0px 2.03px 3.25px 0px #00000040;
  color: #000000;
}

.banner-card-desktop {
  padding: 8px;
  gap: 8px;
  border-radius: 6px;
  box-shadow: 0px 2.63px 11.78px 0px #00000040;
  color: #000000;
}

.banner-welcome-text {
  width: 138.26px;
  height: 22px;
  margin: 0 auto;

  font-size: 16px;
  font-weight: 700;
  line-height: 21.82px;
  text-align: center;
}

.banner-template-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 21.82px;
  text-align: center;
}

.banner-online-text {
  margin: 0 auto;
  width: 108.04px;
  height: 16px;

  font-size: 12px;
  font-weight: 700;
  line-height: 16.37px;
  text-align: center;
}

.p-button-icon-only.help-tooltip-button {
  width: 32px !important;
  height: 32px !important;
  padding: 10px !important;
  border-radius: 19px !important;
}

.input-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  text-align: left;
  color: #6c757d;
}

.tooltip-icon.inline-tooltip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  padding: 0px !important;

  i {
    color: #6c757d;
  }
}

.tooltip-icon.inline-tooltip-campanias {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  padding: 0px !important;
  margin-left: 10px !important;
  height: 1.5em; 

  i {
    color: #ffffff;
    line-height: 1;
    font-size: 14px; 
  }
}

.tooltip-icon.inline-tooltip-campanias-default {
  
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  padding: 0px !important;
  margin-left: 10px !important;
  height: 1.5em; 
  margin-top:14px !important;

  i {
    color: #ffffff;
    line-height: 1;
    font-size: 14px; 
  }
}

.inline-tooltip-campanias .p-tooltip {
  max-width: 1000x; 
  white-space: nowrap; 
}

.inline-tooltip-campanias .p-tooltip .p-tooltip-text {
  white-space: nowrap;  /* Mantiene el texto en una sola línea */
}

.tooltip-icon.inline-tooltip-create-campanias {
  
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  padding: 0px !important;
  margin-left: 10px !important;
  height: 1.5em; 
  //margin-top:14px !important;

  i {
    color: rgb(108, 117, 125);
    line-height: 1;
    font-size: 14px; 
  }
}

.custom-grid-container
{
  display: inline-flex !important;
}
.banner-assign-stores-container {
  display: flex;
  gap: 8px;
}

.banner-assign-stores-buttons-section {
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
  align-items: center;
}

.p-button.reassign-button {
  display: flex !important;
  width: 32px !important;
  height: 32px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  padding: 0px !important;
}

.banner-store-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  height: 440px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #dce1e8;
}

.banner-store-list-header {
  display: flex;
  height: 56px;
  padding: 8px 15px;
  align-items: center;
  gap: 14px;
  align-self: stretch;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid var(--Surface-Light-mode-Shadow, #dce1e8);
  background: var(--Surface-Light-mode-Bg, #f3f7fc);
}

.banner-store-input-search-container {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 9px;
  align-self: stretch;
  position: relative;

  .p-field {
    padding: 0px !important;
    margin: 0px !important;
  }
}

.banner-store-input-search {
  padding: 10.5px 7px 10.5px 10.5px !important;
  border-radius: 6px !important;
  border: 1px;
}

.p-field.banner-store-input-search {
  padding: 10.5px 7px 10.5px 10.5px !important;
  margin: 0 !important;
}

.banner-store-input-search-icon {
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.banner-store-list-element {
  display: flex;
  padding: 7px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #fff;

  &.active {
    background: #eef3fc;
  }

  span {
    display: flex;
    padding: 0px 4px;
    align-items: center;
    color: #606060;
    text-align: center;
    font-size: 14px;
  }
}

.banner-store-list-element-container {
  width: 100%;
  max-height: 265px;
  overflow-y: auto;
}

.custom-padding-card.p-card {
  padding: 0px !important;

  .p-card-content {
    padding: 0px !important;
  }

  .p-card-body {
    padding: 0px !important;
  }

  padding: 22px 16px !important;
}

.ad-content-manager-card {
  padding: 0px !important;

  .p-card-content {
    padding: 0px !important;
  }

  .p-card-body {
    padding: 0px !important;
  }

  padding: 21px !important;
}

.custom-modal-header {
  border-radius: 6px !important;

  .p-dialog-content {
    border-radius: inherit !important;
  }

  .p-dialog-header {
    border-radius: inherit !important;
  }

  .p-button-outlined {
    border: 1px solid #194893 !important;
    color: #194893 !important;
  }

  .p-dialog-content {
    padding: 0px 25px 22px 25px !important;
  }

  .p-dialog-header {
    padding: 22px 25px 17px 25px !important;
    .p-dialog-title {
      font-size: 22px !important;
      font-weight: 700 !important;
      color: #194893;
    }
  }
}

.ad-content-help-modal {
  min-width: 680px !important;
}

.subtitle-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.banner-size-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  .device-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;
  }

  .vertical-divider {
    height: 261px;
    width: 1px;
    background: #e3e3e3;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: #e3e3e3;
}

.mobile-banner-error-container,
.desktop-banner-error-container {
  position: absolute;
  top: 11px;
  left: 8px;
  right: 8px;
}

.form-error-alert {
  z-index: 9999;
  width: 100%;
  justify-content: start !important;
  padding: 5px 5px 5px 17px !important;
  border-radius: 6px !important;
  border-left: 6px solid #be0b10 !important;
  background: #f1b5b9d9 !important;
}

.action-button {
  display: flex;
  width: 151px;
  min-height: 38px;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-wrap: nowrap;
}

.bg-white {
  background: #ffffff !important;
}

.w-full {
  width: 100% !important;
}

/* Estilos para el listado de ads */
.p-menu ul {
  flex-direction: column !important;
}
.centered-column {
  text-align: center;
}
/*.p-filter-column:nth-child(2) .p-float-label {
  left: -150px; 
  width: calc(100% + 150px); 
}*/

.p-field.p-col .p-float-label input.p-inputtext,
.p-field.p-col .p-float-label textarea.p-inputtext {
  padding: 0.5rem !important;
}

.image-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
}

.hover-image {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Oscurece la imagen */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 3px;
  transition: opacity 0.3s ease-in-out;
}

.eye-icon {
  font-size: 1.5em;
  color: white;
}

.image-container:hover .hover-image {
  filter: brightness(0.5);
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.vertical-icon {
  transform: rotate(90deg);
  font-size: 1.5rem;
  cursor: pointer;
}

.actions-column {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: transparent;
  border-radius: 4px;
  transition: background-color 0.3s, box-shadow 0.3s;
  transform: translateX(-15px);
}

.actions-column:hover {
  //box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  //background-color: rgba(0, 0, 0, 0.2);
  color: #018454 !important;
}

.actions-column i {
  font-size: 16px;
  color: #495057;
  transition: color 0.3s;
}

/* Cambia el color del ícono cuando está en hover */
.actions-column:hover i {
  color: #018454 !important; /* Forzar el color en el hover para el ícono */
}

.p-inputtext::placeholder {
  font-size: 15px;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox.p-component::after {
  position: absolute;
  content: "Todo";
  text-wrap: nowrap;
  color: #495057;
  font-weight: normal;
  margin-left: 1.85rem;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  text-align: left;
  font-size: 15px;
  color: #adb5bd !important;
  padding-left: 0.5rem !important;
}

.text-default {
  font-size: 14px; /* Cambia este valor según lo necesites */
}
