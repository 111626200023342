.underline {
  text-decoration: underline;
}

@for $i from 0 through 32 {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .gap-#{$i} {
    gap: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .m-#{$i} {
    margin: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

@for $i from 0 through 32 {
  .p-#{$i} {
    padding: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }
}

@for $i from 0 through 32 {
  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }
}

.p-field.p-m-0 {
  margin: 0px !important;
}

.p-field.p-mt-0 {
  margin-top: 0px !important;
}

.p-field.p-mb-0 {
  margin-bottom: 0px !important;
}

.p-field.p-ml-0 {
  margin-left: 0px !important;
}

.p-field.p-mr-0 {
  margin-right: 0px !important;
}

.p-field.p-p-0 {
  padding: 0px !important;
}

.p-field.p-pt-0 {
  padding-top: 0px !important;
}

.p-field.p-pb-0 {
  padding-bottom: 0px !important;
}

.p-field.p-pl-0 {
  padding-left: 0px !important;
}

.p-field.p-pr-0 {
  padding-right: 0px !important;
}

.bg-white {
  background: #ffffff !important;
}

.w-full {
  width: 100% !important;
}

.w-1\/2 {
  width: 50% !important;
}

.text-danger {
  color: #be0b10;
}

.text-primary {
  color: #194893;
}

.text-black {
  color: #000000;
}

.text-white {
  color: #ffffff;
}

.text-success {
  color: #018454;
}

.text-underline {
  text-decoration: underline;
}

@for $i from 0 through 10 {
  .underline-offset-#{$i} {
    text-underline-offset: #{$i}px;
  }
}

.underline-0\.5 {
  text-decoration-thickness: 0.5px;
}
@for $i from 0 through 10 {
  .underline-#{$i} {
    text-decoration-thickness: #{$i}px;
  }
}

.w-fit {
  width: fit-content !important;
}

.w-auto {
  width: auto !important;
}

.hover-bg-green:hover {
  background-color: #E5FFF6 !important; 
}

.hover-text-green:hover {
  color: #018454 !important;
}

.tooltip-icon.inline-tooltip {
  &.text-white i {
    color: #ffffff;
  }
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 700 !important;
}

.p-button.p-0 {
  padding: 0px !important;
}

.p-field.p-col.m-0 {
  margin: 0px !important;
}