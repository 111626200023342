// font awesome
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

//variables comunes
@import "../common/_variables";

//variables de proyecto
@import "./_variables-wfi";

//todos los estilos comunes
@import "../common/_all-common";

//todos los estilos especificos de proyecto
@import "./_all-wfi";