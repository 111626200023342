.bloque-splash-container {
    display: block;
    width: 99%;
    align-self: center;
    position: relative;
    //box-shadow: 0 0 0 1px red;
    border-radius: 10px;

    &.hover {
        //box-shadow: 0 0 0 2px #00b0ff;
        border: 1px dashed #00b0ff;
    }
}

.splash-edit-buttons {
    position: absolute;

    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 176, 255, 10%);
    background-color: #c1daf8b8;
    padding: 0;

    .p-button-outlined {
        background-color: white !important;
    }
}

.splash-container {
    margin: 20px auto;
    border: 8px solid black;
    border-radius: 30px;
    padding: 2px;
    width: 360px;
    height: 700px;
    overflow: auto;
    background: #f6f8fc;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.p-scrollpanel p {
    margin-top: 0;
}