.sd .p-selectbutton {
    .p-button.p-highlight {
        background: $main-color-1;
        border-color: $main-color-1;


        &:hover {
            background: $main-color-2;
            border-color: $main-color-2;

        }
    }
}

.sd .p-button,
.p-dialog .p-button {

    background: $secondary-color-1;
    border: 1px solid $secondary-color-1;

    &:enabled:hover {
        background: lighten($secondary-color-1, 10);
        color: #ffffff;
        border-color: lighten($secondary-color-1, 10);
    }


    &:enabled:active {
        background: $secondary-color-2;
        color: #ffffff;
        border-color: $secondary-color-2;
    }

    &.p-button-secondary {
        color: #ffffff;
        background: $main-color-1;
        border: 1px solid $main-color-1;

        &:enabled:hover {
            background: $main-color-2;
            color: #ffffff;
            border-color: $main-color-2;
        }

        &:enabled:focus {
            box-shadow: 0 0 0 0.2rem #beccd2;
        }

        &:enabled:active {
            background: $main-color-3;
            color: #ffffff;
            border-color: $main-color-3;
        }
    }
}