.badge {
  display: inline-flex;
  padding: 2px 6px;
  align-items: center;
  gap: 2px;
  border-radius: 12px;
  color: #fff;
}

.badge-regular {
  border-radius: 8px;
}

.badge-primary {
  background-color: #194893;
}

.badge-info {
  background: #2196f3;
}

.badge-success {
  background-color: #44A143;;
}

.badge-gray {
  background-color: #606060;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.dot-primary {
  background-color: #194893;
}

.dot-info {
  background-color: #2196f3;
}

.dot-success {
  background-color: #44A143;
}

.dot-warning {
  background-color: #FFC700;;
}

.dot-amber {
  background-color: #F56B1D;
}

.dot-pink {
  background-color: #E04460;
}

.dot-gray {
  background-color: #606060;
}

.bg-gray {
  background-color: #606060;
}
