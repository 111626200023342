.trajectory-map {
    .trajectory-title {
        padding: 0;
        margin: 0;
        flex-grow: 0;

        h1 {
            font-size: 20px;
            font-weight: 700;
            line-height: 27.28px;
            text-align: left;
            padding: 0;
            margin: 0;
        }

        margin-bottom: 14px;
    }


    >.p-grid {
        height: calc(100vh - (91px + 104px + 2em));

        .p-card-body {
            height: 100%;

            .p-card-content {
                height: 100%;

                &>div {
                    height: 100%;

                    >.p-grid {
                        height: 100%;

                    }
                }
            }
        }
    }

    .preview-container {
        .progress-spinner {
            &::before{
                display: none;
            }

            width: 100%;
            height: 100%;

            .p-progress-spinner {
                width: 100%;
                height: 100%;
                .p-progress-spinner-svg {
                    width: 50%;
                    height: 50%;
                    circle {

                        stroke-width: 4px;
                    }
                }
            }

            @keyframes p-progress-spinner-color-2 {
                0%, 100% {
                    stroke:  white;
                }
            }
        }
    }

    .ap-point-player {
        & > path {
            fill: #D9D9D9;
        }
        &:hover {
            & > path {
                fill: #999999;
            }
        }
    }

    .p-slider--orange {
        .p-slider-handle  {
            opacity: 0;
            height: 24px;
            top: -11px !important;
        }
    }

    .main-card {
        flex-grow: 1;
    }

    .info-col {
        padding-left: 0;
        width: fit-content;

        .p-card.p-component {
            padding: 21px 16px;
            margin-bottom: 0;
            max-width: 198px;
            width: 100%;

            .p-card-body {
                padding: 0;

                .p-card-content {
                    padding: 0;

                    &>div {
                        display: flex;

                        .p-grid.p-grid.p-ai-center {
                            margin: 0;
                            flex-grow: 1;
                            width: 100%;

                            .header-titulo-pagina {
                                padding: 0;

                                h1 {
                                    font-size: 22px;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
        }

    .right-col {
        margin-left: 28px;

        .rating {
            .p-ai-center.p-col.header-titulo-pagina.p-d-flex {
                margin-bottom: 0;
                padding-bottom: 0;

                h1 {
                    font-size: 20px;
                    font-weight: 700 !important;
                }
            }
        }
    }

    .table-duration {
        h1 {
            font-size: 20px;
            font-weight: 700 !important;
        }
    }
}