
.custom-title-wrapper .p-col-align-center {
    align-self: flex-start !important;
  }

  .custom-tooltip {
    margin-left: 10px !important; /* Espacio a la izquierda del div */
  }
  
  .custom-tooltip i {
    color: white !important; /* Cambia el color del ícono a blanco */
  }

  .tooltip-icon.inline-tooltip i {
    
    padding-left: 10px !important;
  }

.p-datatable-hoverable-rows .p-selectable-row:hover {
    background-color: #E5FFF6 !important; 
  }

  .custom-pause-icon {
    font-size: 0.8rem; /* Ajusta el tamaño del ícono */
    font-weight: bold; /* Aumenta el grosor */
  }
  .custom-play-icon {
    font-size: 1rem; /* Ajusta el tamaño del ícono */
    font-weight: bold; /* Aumenta el grosor */
  }

  .custom-tab .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #009D63;
    ; /* Cambia el color del borde a verde */
    color: #009D63; /* Cambia el color del texto a verde */
  }



  .p-grid {
    display: flex;
    justify-content: space-between; /* Alinea los elementos a los lados */
    align-items: center; /* Centra verticalmente */
}



.action-button {
    margin: 0; /* Asegúrate de que no haya márgenes */
}


.custom-table-page .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: white; /* O usa 'transparent' para que no cambie el fondo */
  color: inherit; /* Esto permite que el color de texto permanezca igual */
}

.custom-table-page .p-datatable-scrollable-body {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  overflow: hidden; /* Asegura que el borde redondeado se vea bien */
}

.loaderlooker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px; /* Ajusta según necesites */
  font-size: 18px;
  color: #000; /* Color negro para que sea visible */
  background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco semi-transparente */
  z-index: 10;
  position: relative;
  text-align: center;
}

.card-style {
  background-color: #fff; /* Fondo blanco */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para dar efecto de elevación */
  padding: 16px; /* Relleno interno */
  border: 1px solid #e0e0e0; /* Opcional: borde ligero */
  transition: box-shadow 0.3s ease; /* Suaviza la sombra al pasar el ratón */
  margin: 20px 8px 0 8px;
}

.card-style:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Sombra más intensa al hacer hover */
}
 