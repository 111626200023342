.p-relative {
    position: relative;
}

.p-slider.p-slider--orange {
    .p-slider-range {
        background: #FF6464;
    }
    
    .p-slider-handle {
        border-color: #FF6464;

        &:focus {
            box-shadow: 0 0 0 0.2rem #ffc0c0!important;
        }

        &:hover {
            background: #FF6464!important;
            border-color: #FF6464!important;
        }
    }
}
.visits-table {
    visibility: visible;

    .p-filter-column {

        // duration
        &:nth-child(4) {
            padding-left: 0;
            padding-right: 0;

            input {
                padding: 0.5rem 0.7rem !important;
            }
        }

        // rating
        &:nth-child(6) {
            .p-multiselect-label.p-placeholder {
                padding-left: 0.9rem !important;
            }
        }

        // emotion
        &:nth-child(7) {
            .p-multiselect-label.p-placeholder {
                padding-left: 0.9rem !important;
            }
        }
    }

    .p-datatable-tbody {
        td[role="cell"] {

            // date
            &:nth-child(1) {
                width: 300px;
                padding-right: 0;
            }

            // duration
            &:nth-child(4) {
                width: 175px;
            }


            // rating
            &:nth-child(6) {
                padding: 0;
            }

            // emotion
            &:nth-child(7) {
                padding: 0;
            }
        }
    }
}

.p-datepicker.restrict-current-month {
    .vis-hidden {
        visibility: hidden !important;
        pointer-events: none;
    }
    
    .p-datepicker-prev {
        visibility: hidden;
        pointer-events: none;
    }
    
    .p-datepicker-next {
        visibility: hidden;
        pointer-events: none;
    }
}