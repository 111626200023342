$mnv-logo-orange: #FFB600;

$main-color-1: #009d63;
$main-color-2: #51cf91;
$main-color-3: #006d38;
$main-color-light-background: lighten($main-color-1, 45);

$primary: #1D5BA4;

$secondary-color-1: #194893;
$secondary-color-2: #0F3877;
$secondary-color-3: #17468D;
