.table-row-hover-bg-green {
    tbody tr:hover {
        background-color: #E5FFF6 !important; 
    }
}

.table-cell-hover-bg-green {
    tbody td:hover {
        background-color: #E5FFF6 !important; 
    }
}

.ad-table {
    th.p-filter-column {
        padding: 0 !important;

        .p-field, .p-col {
            padding-left: 0.5rem !important;
            padding-right: 0rem !important;
        }

        .p-d-flex.p-col-12 {
            padding: 0 !important;
        }
    }
}